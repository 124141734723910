import getSearchSuggestions from "lib/getSearchSuggestionsNew";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";
import useAsyncFn from "react-use/lib/useAsyncFn";
import CRLogoLoader from "components/svg/CogRoundelLoader";
import logEvent from "lib/ga/miscEvent";
import logNewSearchSubmit from "lib/ga/newSearchSubmit";
import GoogleSearchInput from "./GoogleSearchInput";
import { APIProvider } from '@vis.gl/react-google-maps';

interface Props {
  title: string,
  subTitle?: string,
  containerMargin?: string
}

import styles from './SearchGoogle.module.css'

const getSchemeItemUri = (item) => `/scheme/${item.uri.toLocaleLowerCase()}-${item.id}`

const GoogleSearch = () => {

  const [type, setType] = useState('PROPERTY');
  const [forSale, setForSale] = useState(false);
  const [criteria, setCriteria] = useState('LOCATION');
  const [inputValue, setInputValue] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  //for scheme name search
  const [suggestions, setSuggestions] = useState([]);
  const [nameUri, setNameUri] = useState(null);

  const [isRedirecting, setisRedirecting] = useState(false);
  const [isSearching, setisSearching] = useState(false);
  const [isLoaded, setIsloaded] = useState(false);


  let router = useRouter();
  let query = useSearchParams();

  useEffect(() => {
    if (query.get('searchType')) {
      setType(query.get('searchType') === "flexible" ? "POPUP" : query.get('searchType').toUpperCase())
    }
  }, []);

  //for scheme name redirect
  useEffect(() => {

    if (nameUri) {
      setisRedirecting(true);

      logEvent({
        category: "Search",
        action: "User made a search",
        label: nameUri,
      });
      logNewSearchSubmit({
        type: type,
        category: "Search",
        criteria: criteria,
        url: nameUri,
      });

      router.push(nameUri);
    } else {
      setisRedirecting(false)
    }

  }, [nameUri]);

  const [suggestionsState, fetchSuggestions] = useAsyncFn(
    async () => {
      if (!inputValue) return;

      setisSearching(true);

      const newSuggestions = await getSearchSuggestions(inputValue, 'scheme');

      setSuggestions(newSuggestions);
      setisSearching(false);
    },
    // Variables need to be defined here in order to be used in the async function. If not defined they will exist with their initial value.
    [inputValue]
  );
  const sendQuery = () => {
    // make async call for suggestions
    fetchSuggestions();
  };

  const [libraries] = useState(['places']);

  const [debounceState, cancelDebounce] = useDebounce(
    // The fucntion to call after the debounce period
    () => {
      if (!isRedirecting) {
        sendQuery();
      }

    },
    // The period we wait
    500,
    // The depenndencies whose changes we react to to trigger the debounce. (Same as useEffect)
    [inputValue, isRedirecting]
  );

  /* const { isLoaded, loadError } = useJsApiLoader({
     id: 'google-map-script',
     googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_APIS_KEY_CSR,
     libraries: libraries
   });*/
  return (
    <form className={styles.SearchContainer}>
      <section className={styles.SearchChangeWrapper}>

        <button className={type === "PROPERTY" ? `${styles.activeTab} ${styles.ChangeSearch}` : styles.ChangeSearch}
          onClick={(e) => {
            e.preventDefault();
            if (e.detail) {

              setType("PROPERTY");
              setCriteria("LOCATION");
              setForSale(false);
            }

          }}
        >
          Shops
        </button>
        <button className={type === "SCHEME" ? `${styles.activeTab} ${styles.ChangeSearch}` : styles.ChangeSearch}
          onClick={(e) => {
            e.preventDefault();
            setType("SCHEME");
            setCriteria("LOCATION");
            setForSale(false);
          }}
        >
          Schemes
        </button>

      </section>

      <section className={styles.section}>
        <div className={styles.InputContainer}>
          <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_APIS_KEY_CSR} onLoad={() => setIsloaded(true)}>
            {criteria == "LOCATION" && isLoaded &&
              <GoogleSearchInput type={type} forSale={forSale} />
            }

            {criteria == "LOCATION" && !isLoaded &&
              <input id="google-search-input" type="text" placeholder="Search by city, town, street or postcode" autoComplete="off" />
            }

          </APIProvider>
          {criteria == "NAME" &&
            <div className={styles.InputWrapper}>
              <input
                id="name-search"
                value={inputValue}
                type="text"
                placeholder="Begin typing the name of the retail scheme"
                autoComplete="off"
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setActiveSuggestion(0)
                }}
                onKeyDown={(e) => {
                  if (e.key == "ArrowDown") {
                    let newIndex = activeSuggestion + 1;
                    if (newIndex >= 5) {
                      newIndex = 0;
                    }
                    setActiveSuggestion(newIndex);
                  }
                  if (e.key == "ArrowUp") {
                    let newIndex = activeSuggestion - 1;
                    if (newIndex < 0) {
                      newIndex = 4;
                    }
                    setActiveSuggestion(newIndex);
                  }
                  if (e.key == "Enter") {
                    let item = suggestions[activeSuggestion];
                    // CR-1369
                    if (item) {
                      setNameUri(getSchemeItemUri(item));
                      setInputValue(item.name);
                      setisRedirecting(true);
                    }
                  }
                }}
                disabled={isRedirecting}
              />
              {(isRedirecting || isSearching) && <div className={styles.Loader} >
                <CRLogoLoader
                  gradToUse={type === "POPUP" ? "flexible" : "retail"}
                />
              </div>}
            </div>}


          {type == "SCHEME" && <div className={styles.CriteriaTabs}>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCriteria("LOCATION");
              }}
              id="schemeByLocationTab"
              className={`${criteria === "LOCATION" ? styles.active : ""}`}
            >
              Location
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCriteria("NAME");
              }}
              id="schemeByNameTab"
              className={`${criteria === "NAME" ? styles.active : ""}`}
            >
              Name
            </button>
          </div>}

          {type == "PROPERTY" && <div className={styles.CriteriaTabs}>
            <button
              onClick={(e) => {
                e.preventDefault();
                setForSale(false);
              }}
              id="locationForRent"
              className={`${!forSale ? styles.active : ""}`}
            >
              For Rent
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setForSale(true);
              }}
              id="locationForSale"
              className={`${forSale ? styles.active : ""}`}
            >
              For Sale
            </button>
          </div>}


          {criteria == "NAME" && <ul className={styles.Suggestions} >
            {suggestions.length > 0 && !isRedirecting &&
              suggestions.map((item, index) => (
                <li className={styles.Suggestion}
                  key={`${item.id}${index}`}
                  onClick={() => {
                    setNameUri(getSchemeItemUri(item));
                    setInputValue(item.name);
                    setisRedirecting(true);
                  }}
                >
                  {item.name}
                </li>
              ))}
          </ul>}
        </div>
      </section>
    </form>
  )
}

export default GoogleSearch;
